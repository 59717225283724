import React from 'react'

import Module, { CalcDataFn } from '../Module'

import * as constants from '../constants'

function calcTenPercentage(value: number) {
  const delta = Math.max(value - constants.TenMinValue, 0)

  return Math.floor((100 * delta) / constants.LevelMod) / 1000
}

export default function Tenacity() {
  const dataRow = React.useCallback<CalcDataFn>((value, { percent }) => {
    const tenPercentage = calcTenPercentage(value)
    const tenDamage = 1 + tenPercentage
    const tenMitigation = Math.max(1 - tenPercentage, 0)

    return [percent(tenDamage), percent(tenMitigation)]
  }, [])

  return (
    <Module
      label='Tenacity'
      minValue={constants.TenMinValue}
      headLabels={React.useMemo(() => ['Damage multiplier', 'Mitigation%'], [])}
      calcData={dataRow}
    />
  )
}
