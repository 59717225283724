import React from 'react'

import Module, { CalcDataFn } from '../Module'

import * as constants from '../constants'

function calcDefense(
  value: number,
  { protect, feyCovenant }: { protect: boolean; feyCovenant: boolean }
) {
  const delta = Math.max(value, 1)
  const protectMulti = protect ? 1.15 : 1
  const feyCovenantMulti = feyCovenant ? 1.2 : 1

  return Math.min(
    Math.floor(
      (15 * Math.floor(Math.floor(delta * protectMulti) * feyCovenantMulti)) /
        constants.LevelMod
    ) / 100,
    1
  )
}

export default function Defense(props: {
  protect: boolean
  feyCovenant: boolean
}) {
  const dataRow = React.useCallback<CalcDataFn>(
    (value, { percent }) => {
      const mitigation = calcDefense(value, props)
      return [percent(mitigation)]
    },
    [props.protect, props.feyCovenant]
  )

  return (
    <Module
      label='Defense'
      minValue={1}
      headLabels={React.useMemo(() => ['Damage Mitigation'], [])}
      calcData={dataRow}
    />
  )
}
