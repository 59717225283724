import React from 'react'

import Module, { CalcDataFn } from '../Module'

import * as constants from '../constants'

function calcDetMultiplier(value: number) {
  const delta = Math.max(value - constants.DetMinValue, 0)

  return (1000 + Math.floor((130 * delta) / constants.LevelMod)) / 1000
}

export default function Determination() {
  const dataRow = React.useCallback<CalcDataFn>((value, { percent }) => {
    const detMultiplier = calcDetMultiplier(value)
    return [percent(detMultiplier)]
  }, [])

  return (
    <Module
      label='Det'
      minValue={constants.DetMinValue}
      headLabels={React.useMemo(
        () => ['Damage/Healing Received multiplier'],
        []
      )}
      calcData={dataRow}
    />
  )
}
