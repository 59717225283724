import React from 'react'
import {
  AppBar,
  Typography,
  Toolbar,
  Paper,
  FormControl,
  FormLabel,
  Divider,
  Grid,
  FormGroup,
  FormControlLabel,
  Switch,
  Checkbox,
  Select,
  MenuItem
} from '@material-ui/core'

import Slider from '@material-ui/lab/Slider'

import styled from './styled'
import Critical from './Modules/Critical'
import { CritBuffsAvailable, BuffNames, CardType } from './constants'
import Determination from './Modules/Determination'
import Tenacity from './Modules/Tenacity'
import DirectHit from './Modules/DirectHit'
import Speed from './Modules/Speed'
import Defense from './Modules/Defense'

const StyledPaper = styled(Paper)(theme => ({
  margin: theme.spacing.unit,
  padding: theme.spacing.unit,
  maxWidth: 800,
  [theme.breakpoints.up(800 + theme.spacing.unit * 2)]: {
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}))

const StyledDivider = styled(Divider)(theme => ({
  margin: `${theme.spacing.unit * 2}px 0`
}))

const StyledFormControl = styled(FormControl)({
  display: 'block'
})

const NoWrapFormGroup = styled(FormGroup)({
  flexWrap: 'nowrap'
})

const StyledSlider = styled(Slider)(() => ({
  width: 200,
  margin: '0 16px',
  padding: '22px 0',
  alignSelf: 'center'
}))

type SetCritAction<T extends keyof CritBuffsAvailable> = {
  type: 'set'
  name: T
  value: CritBuffsAvailable[T]
}

type ToggleBeastEnabled = {
  type: 'toggleBeast'
  value: boolean
}

function critBuffReducer(
  buffs: CritBuffsAvailable & { beastEnabled: boolean },
  action: SetCritAction<keyof CritBuffsAvailable> | ToggleBeastEnabled
) {
  if (action.type === 'set') {
    return {
      ...buffs,
      [action.name]: action.value
    }
  }
  if (action.type === 'toggleBeast') {
    return {
      ...buffs,
      beastEnabled: !buffs.beastEnabled
    }
  }

  return buffs
}
function useCritHandler(
  buffName: BuffNames.BeastGauge,
  dispatch: React.Dispatch<SetCritAction<BuffNames.BeastGauge>>
): (_: unknown, value: number) => void
function useCritHandler<
  T extends Exclude<keyof CritBuffsAvailable, BuffNames.BeastGauge>
>(
  buffName: T,
  dispatch: React.Dispatch<SetCritAction<T>>
): (_: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void
function useCritHandler<T extends keyof CritBuffsAvailable>(
  buffName: T,
  dispatch: React.Dispatch<SetCritAction<T>>
) {
  switch (buffName) {
    case BuffNames.BattleLitany:
    case BuffNames.BardCriticalUp:
    case BuffNames.ChainStratagem:
    case BuffNames.StraightShot:
    case BuffNames.InternalRelease:
    case BuffNames.CritGuaranteed:
      return React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
          dispatch({ type: 'set', name: buffName, value: e.target.checked })
        },
        [buffName, dispatch]
      )
    case BuffNames.BeastGauge:
      return React.useCallback((_: unknown, value: number) => {
        dispatch({
          type: 'set',
          name: buffName,
          value: value as 0 | 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90 | 100
        })
      }, [])
    case BuffNames.Spear:
      return React.useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
          dispatch({
            type: 'set',
            name: buffName,
            value: e.target.value as CardType
          })
        },
        [buffName, dispatch]
      )
  }
  return React.useCallback(() => {}, [])
}

const SpearFormGroup = styled(FormGroup)({
  alignItems: 'center'
})

const SpearSelect = styled(Select)(theme => ({
  '&&': {
    margin: `0 ${theme.spacing.unit * 2}px`
  }
}))

export default function App() {
  const [critBuffs, critBuffsDispatch] = React.useReducer(critBuffReducer, {
    [BuffNames.BattleLitany]: false,
    [BuffNames.BardCriticalUp]: false,
    [BuffNames.BeastGauge]: 0 as 0,
    [BuffNames.Spear]: CardType.None,
    [BuffNames.ChainStratagem]: false,
    [BuffNames.StraightShot]: false,
    [BuffNames.InternalRelease]: false,
    [BuffNames.CritGuaranteed]: false,
    beastEnabled: false
  })
  const [battleVoice, setBattleVoice] = React.useState(false)

  return (
    <>
      <AppBar position='static'>
        <Toolbar>
          <Typography variant='h5' color='inherit'>
            Substat Interval Calculator
          </Typography>
        </Toolbar>
      </AppBar>
      <StyledPaper>
        <Typography paragraph>
          Based on{' '}
          <a href='https://docs.google.com/spreadsheets/d/1Y6wP1rq0b-3Oh45Oo1slFQGyKUqrkfGYk5TjNandLqE/edit'>
            this
          </a>
        </Typography>
        <StyledFormControl component='fieldset'>
          <Grid container>
            <Critical
              buffs={React.useMemo(
                () =>
                  !critBuffs.beastEnabled
                    ? { ...critBuffs, [BuffNames.BeastGauge]: 0 as 0 }
                    : critBuffs,
                [critBuffs, critBuffs.beastEnabled]
              )}
            />
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={critBuffs[BuffNames.BattleLitany]}
                    onChange={useCritHandler(
                      BuffNames.BattleLitany,
                      critBuffsDispatch
                    )}
                    color='primary'
                  />
                }
                label='Battle Litany'
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={critBuffs[BuffNames.ChainStratagem]}
                    onChange={useCritHandler(
                      BuffNames.ChainStratagem,
                      critBuffsDispatch
                    )}
                    color='primary'
                  />
                }
                label='Chain Stratagem'
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={critBuffs[BuffNames.StraightShot]}
                    onChange={useCritHandler(
                      BuffNames.StraightShot,
                      critBuffsDispatch
                    )}
                    color='primary'
                  />
                }
                label='Straight Shot'
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={critBuffs[BuffNames.BardCriticalUp]}
                    onChange={useCritHandler(
                      BuffNames.BardCriticalUp,
                      critBuffsDispatch
                    )}
                    color='primary'
                  />
                }
                label='Bard Song'
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={critBuffs[BuffNames.InternalRelease]}
                    onChange={useCritHandler(
                      BuffNames.InternalRelease,
                      critBuffsDispatch
                    )}
                    color='primary'
                  />
                }
                label='Internal Release'
              />
              <SpearFormGroup row>
                <FormLabel>Spear</FormLabel>
                <SpearSelect
                  value={critBuffs[BuffNames.Spear]}
                  onChange={useCritHandler(BuffNames.Spear, critBuffsDispatch)}
                >
                  <MenuItem value={CardType.None}>None</MenuItem>
                  <MenuItem value={CardType.Expanded}>Expanded</MenuItem>
                  <MenuItem value={CardType.Regular}>Regular/Extended</MenuItem>
                  <MenuItem value={CardType.Enhanced}>Enhanced</MenuItem>
                </SpearSelect>
              </SpearFormGroup>
              <NoWrapFormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={critBuffs.beastEnabled}
                      onChange={React.useCallback(
                        (e: React.ChangeEvent<HTMLInputElement>) => {
                          critBuffsDispatch({
                            type: 'toggleBeast',
                            value: e.target.checked
                          })
                        },
                        []
                      )}
                      color='secondary'
                    />
                  }
                  label='Beast Gauge'
                />
                <FormControlLabel
                  control={
                    <StyledSlider
                      disabled={!critBuffs.beastEnabled}
                      min={0}
                      max={100}
                      step={10}
                      value={critBuffs[BuffNames.BeastGauge]}
                      onChange={useCritHandler(
                        BuffNames.BeastGauge,
                        critBuffsDispatch
                      )}
                    />
                  }
                  label={critBuffs[BuffNames.BeastGauge]}
                  labelPlacement='end'
                />
              </NoWrapFormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={critBuffs[BuffNames.CritGuaranteed]}
                    onChange={useCritHandler(
                      BuffNames.CritGuaranteed,
                      critBuffsDispatch
                    )}
                    color='secondary'
                  />
                }
                label='Guaranteed'
              />
            </FormGroup>
          </Grid>
          <StyledDivider />
          <Grid container>
            <DirectHit battleVoice={battleVoice} />
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={battleVoice}
                    onChange={React.useCallback(
                      (e: React.ChangeEvent<HTMLInputElement>) => {
                        setBattleVoice(e.target.checked)
                      },
                      []
                    )}
                    color='primary'
                  />
                }
                label='Battle Voice'
              />
            </FormGroup>
          </Grid>
          <StyledDivider />
          <Determination />
          <StyledDivider />
          <Speed />
          <Typography>Buffs TODO everything is horrible</Typography>
          <StyledDivider />
          <Tenacity />
          <StyledDivider />
          <Defense protect feyCovenant={false} />
        </StyledFormControl>
      </StyledPaper>
    </>
  )
}

// const GlobalStyle = createGlobalStyle`
// :root {
//   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
//     'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//     sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// body {
//   margin: 0;
//   padding: 0;
// }

// code {
//   font-family: 'Fira Code', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }
// `
