import React from 'react'
import warning from 'warning'

import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography
} from '@material-ui/core'

import NumericField from './NumericField'

import styled from './styled'

interface Formatters {
  percent(value: number): string
  time(value: number, unit: 's'): string
}

export type CalcDataFn = (
  value: number,
  formatters: Formatters
) => ReadonlyArray<string>

export default React.memo(Module)

const TableCellPrimary = styled(TableCell)(theme => ({
  color: theme.palette.primary.main
}))

function Module(props: {
  label: string
  minValue: number
  headLabels: ReadonlyArray<string>
  calcData: CalcDataFn
  columnBreakpoints?: boolean
}) {
  const formatters = React.useMemo<Formatters>(() => {
    const percent = new Intl.NumberFormat(undefined, {
      style: 'percent',
      maximumFractionDigits: 3
    })
    const time = new Intl.NumberFormat(undefined, {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    })
    return {
      percent(value) {
        return percent.format(value)
      },
      time(value, unit) {
        return time.format(value) + unit
      }
    }
  }, [])

  const [value, setValue] = React.useState(props.minValue)
  const handleValueChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(parseInt(e.target.value, 10))
    },
    []
  )

  const valid = value >= props.minValue

  const [
    data,
    { nextBreakpoint, columnAtBreakpoint, columnNextBreakpoint }
  ] = useCalcWithBreakpoints(value, props.minValue, formatters, props.calcData)

  warning(
    data.length === props.headLabels.length,
    'calcData should generate as many entries as the headLabels'
  )

  // TODO: memoize calcData results (useMemo won't do) and use it to calculate next/previous breakpoints

  return (
    <Grid
      container
      direction='row'
      alignItems='flex-end'
      wrap='nowrap'
      spacing={8}
    >
      <Grid container item direction='column' xs={2}>
        <Grid item>
          <NumericField
            label={props.label}
            value={value}
            onChange={handleValueChange}
            error={!valid}
            helperText={!valid && `Value must be >= ${props.minValue}`}
          />
        </Grid>
        <Grid item>
          <Typography color='textSecondary' variant='caption'>
            Next Breakpoint
          </Typography>
          <Typography color='primary' gutterBottom>
            {nextBreakpoint}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs>
        <Table padding='dense'>
          <TableHead>
            <TableRow>
              {props.headLabels.map((label, i) => (
                <TableCell numeric key={i}>
                  {label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {data.map((label, i) => {
                return (
                  <TableCell
                    numeric
                    key={i}
                    style={{
                      fontWeight: columnAtBreakpoint[i] ? 'bold' : undefined
                    }}
                  >
                    {label}
                  </TableCell>
                )
              })}
            </TableRow>
            {props.columnBreakpoints && (
              <TableRow>
                {columnNextBreakpoint.map((value, i) => (
                  <TableCellPrimary numeric key={i}>
                    {value}
                  </TableCellPrimary>
                ))}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  )
}

const IterLimit = 300

function findNextBreakpoints(
  start: ReadonlyArray<string>,
  value: number,
  formatters: Formatters,
  calcData: CalcDataFn
): ReadonlyArray<number> {
  const result: (number | undefined)[] = start.map(_ => undefined)

  let i = value || 0
  let iterationLimit = IterLimit
  for (
    let current = calcData(++i, formatters);
    iterationLimit >= 0 && result.some(x => x === undefined);
    current = calcData(++i, formatters), iterationLimit -= 1
  ) {
    for (const [column, columnValue] of current.entries()) {
      if (result[column] === undefined && columnValue !== start[column]) {
        result[column] = i
      }
    }
  }

  return result.map(x => (x !== undefined ? x : Infinity))
}

function isColumnAtBreakpoint(
  column: number,
  start: ReadonlyArray<string>,
  value: number,
  formatters: Formatters,
  calcData: CalcDataFn
): boolean {
  if (!value) {
    return false
  }
  const prev = calcData(value - 1, formatters)
  return !Object.is(prev[column], start[column])
}

function useCalcWithBreakpoints(
  value: number,
  minValue: number,
  formatters: Formatters,
  calcData: CalcDataFn
) {
  const result = calcData(value || 0, formatters)
  const breakpoints = React.useMemo(
    () => {
      const columnNextBreakpoint =
        value < minValue
          ? result.map(() => minValue)
          : findNextBreakpoints(result, value || 0, formatters, calcData)
      const nextBreakpoint = Math.min(Infinity, ...columnNextBreakpoint)
      return {
        nextBreakpoint,
        columnNextBreakpoint,
        columnAtBreakpoint: result.map((_, i) =>
          isColumnAtBreakpoint(i, result, value, formatters, calcData)
        )
      }
    },
    [result, value, minValue, calcData]
  )

  return [result, breakpoints] as [typeof result, typeof breakpoints]
}
