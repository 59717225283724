import React from 'react'

import Module, { CalcDataFn } from '../Module'

import * as constants from '../constants'

function calcDotScaling(value: number) {
  const delta = Math.max(value - constants.SpeedMinValue, 0)

  return 1 + Math.floor((130 * delta) / constants.LevelMod) / 1000
}

function calcCastScaling(
  baseValue: 2 | 2.5 | 2.8 | 3 | 3.5 | 4,
  value: number
) {
  const delta = Math.max(value - constants.SpeedMinValue, 0)

  const jobBuff = 0
  const arrowBuff = 0
  const feyWind = 0
  const gcdScalar = Math.floor(
    100 * ((100 - jobBuff) / 100) * ((100 - arrowBuff) / 100) - feyWind
  )

  return Math.max(
    Math.floor(
      Math.floor(
        100 *
          gcdScalar *
          (Math.floor(
            (baseValue *
              1000 *
              (1000 - Math.floor((130 * delta) / constants.LevelMod))) /
              1000
          ) /
            1000)
      ) / 100
    ) / 100,
    1.5
  )
}

export default function Speed(_props: {}) {
  const dataRow = React.useCallback<CalcDataFn>((value, { percent, time }) => {
    return [
      percent(calcDotScaling(value)),
      time(calcCastScaling(2, value), 's'),
      time(calcCastScaling(2.5, value), 's'),
      time(calcCastScaling(2.8, value), 's'),
      time(calcCastScaling(3, value), 's'),
      time(calcCastScaling(3.5, value), 's'),
      time(calcCastScaling(4, value), 's')
    ]
  }, [])

  return (
    <Module
      label='Skill/Spell Speed'
      minValue={constants.SpeedMinValue}
      headLabels={React.useMemo(
        () => ['DoT Scaling', '2s', '2.5s', '2.8s', '3s', '3.5s', '4s'],
        []
      )}
      calcData={dataRow}
      columnBreakpoints
    />
  )
}
