import React from 'react'

import Module, { CalcDataFn } from '../Module'

import * as constants from '../constants'

function calcDHRate(value: number) {
  const delta = Math.max(value - constants.DHMinValue, 0)

  return Math.min(Math.floor((550 * delta) / constants.LevelMod) / 1000, 1)
}

function calcMultiplier(rate: number) {
  return 1 + rate * 0.25
}

export default function DirectHit(props: { battleVoice: boolean }) {
  const dataRow = React.useCallback<CalcDataFn>(
    (value, { percent }) => {
      const baseDHRate = calcDHRate(value)
      const dhRate = props.battleVoice ? baseDHRate + 0.15 : baseDHRate
      const multiplier = calcMultiplier(dhRate)

      return [percent(dhRate), percent(multiplier)]
    },
    [props.battleVoice]
  )

  return (
    <Module
      label='Direct Hit'
      minValue={constants.DHMinValue}
      headLabels={React.useMemo(() => ['Rate', 'Average Multiplier'], [])}
      calcData={dataRow}
    />
  )
}
