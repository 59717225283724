import React from 'react'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'

export type Props = TextFieldProps

export default function NumericField(props: Props) {
  const InputProps = React.useMemo(
    () => ({
      ...props.InputProps,
      type: 'number'
    }),
    [props.InputProps]
  )
  const InputLabelProps = React.useMemo(
    () => ({
      ...props.InputLabelProps,
      shrink: true
    }),
    [props.InputLabelProps]
  )

  return (
    <TextField
      {...props}
      InputLabelProps={InputLabelProps}
      InputProps={InputProps}
    />
  )
}
