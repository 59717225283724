export const LevelMod = 2170
export const GCDs: [2000, 2500, 2800, 3000, 3500, 4000] = [
  2000,
  2500,
  2800,
  3000,
  3500,
  4000
]

export const CritMinValue = 364
export const CritBaseRate = 50
export const CritBaseDamage = 400
export const CritBuffRates = 0.15
export const CritWTFisA = 200

export const DHMinValue = 364

export const SpeedMinValue = 364

export enum BuffNames {
  BattleLitany = 'BattleLitany',
  BardCriticalUp = 'BardCriticalUp',
  BeastGauge = 'BeastGauge',
  Spear = 'Spear',
  ChainStratagem = 'ChainStratagem',
  StraightShot = 'StraightShot',
  InternalRelease = 'InternalRelease',
  CritGuaranteed = 'CritGuaranteed'
}

export enum CardType {
  None = 'None',
  Regular = 'Regular',
  Extended = Regular,
  Enhanced = 'Enhanced',
  Expanded = 'Expanded'
}

export interface CritBuffsAvailable {
  [BuffNames.BattleLitany]: boolean
  [BuffNames.BardCriticalUp]: boolean
  [BuffNames.BeastGauge]: 0 | 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90 | 100
  [BuffNames.Spear]: CardType
  [BuffNames.ChainStratagem]: boolean
  [BuffNames.StraightShot]: boolean
  [BuffNames.InternalRelease]: boolean
  [BuffNames.CritGuaranteed]: boolean
}

export const CritBuffCap = 95

export const enabledCritValues = {
  [BuffNames.BattleLitany]: 15,
  [BuffNames.BardCriticalUp]: 2,
  [BuffNames.BeastGauge]: {
    0: 0,
    10: 1,
    20: 2,
    30: 3,
    40: 4,
    50: 5,
    60: 6,
    70: 7,
    80: 8,
    90: 9,
    100: 10
  },
  [BuffNames.Spear]: {
    [CardType.None]: 0,
    [CardType.Regular]: 10,
    [CardType.Enhanced]: 15,
    [CardType.Expanded]: 5
  },
  [BuffNames.ChainStratagem]: 15,
  [BuffNames.StraightShot]: 10,
  [BuffNames.InternalRelease]: 30,
  [BuffNames.CritGuaranteed]: 100
}

export const DetMinValue = 292
export const TenMinValue = 364
