import { withStyles } from '@material-ui/core/styles'

export default (withStyles({
  '@global': {
    body: {
      minHeight: '100vh'
    }
  }
})(function GlobalStyle(_: {}) {
  return null
}) as unknown) as React.ComponentClass
