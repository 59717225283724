import React from 'react'

import Module, { CalcDataFn } from '../Module'

import * as constants from '../constants'

function calcCritRate(value: number) {
  const delta = Math.max(value - constants.CritMinValue, 0)

  return (
    (Math.floor((200 * delta) / constants.LevelMod) + constants.CritBaseRate) /
    1000
  )
}

function calcCritDamage(value: number) {
  const delta = Math.max(value - constants.CritMinValue, 0)

  return (
    (Math.floor((200 * delta) / constants.LevelMod) +
      constants.CritBaseDamage) /
    1000
  )
}

function getBuffValue(
  buffName: constants.BuffNames,
  buffs: constants.CritBuffsAvailable
) {
  switch (buffName) {
    case constants.BuffNames.BattleLitany:
    case constants.BuffNames.BardCriticalUp:
    case constants.BuffNames.ChainStratagem:
    case constants.BuffNames.StraightShot:
    case constants.BuffNames.InternalRelease:
    case constants.BuffNames.CritGuaranteed:
      return buffs[buffName] ? constants.enabledCritValues[buffName] : 0
    case constants.BuffNames.BeastGauge:
      return constants.enabledCritValues[buffName][buffs[buffName]]
    case constants.BuffNames.Spear:
      return constants.enabledCritValues[buffName][buffs[buffName]]
    default:
      return unreachable(buffName)
  }

  function unreachable(reachable: never): never {
    throw new Error(`Unknown buff name ${reachable}`)
  }
}

function applyBuffsToBaseCritRate(
  rate: number,
  buffs: constants.CritBuffsAvailable
) {
  const buffSum =
    Math.min(
      getBuffValue(constants.BuffNames.BattleLitany, buffs) +
        getBuffValue(constants.BuffNames.BardCriticalUp, buffs) +
        getBuffValue(constants.BuffNames.ChainStratagem, buffs) +
        getBuffValue(constants.BuffNames.StraightShot, buffs) +
        getBuffValue(constants.BuffNames.InternalRelease, buffs) +
        getBuffValue(constants.BuffNames.CritGuaranteed, buffs) +
        getBuffValue(constants.BuffNames.BeastGauge, buffs) +
        getBuffValue(constants.BuffNames.Spear, buffs),
      constants.CritBuffCap
    ) / 100
  return Math.min(rate + buffSum, 1)
}

export default function Critical(props: {
  buffs: constants.CritBuffsAvailable
}) {
  const dataRow = React.useCallback<CalcDataFn>(
    (value, { percent }) => {
      const baseCritRate = calcCritRate(value)
      const critRate = applyBuffsToBaseCritRate(baseCritRate, props.buffs)
      const critDamage = calcCritDamage(value)
      const eDamage = 1 + critRate * critDamage

      return [percent(critRate), percent(1 + critDamage), percent(eDamage)]
    },
    [props.buffs]
  )

  return (
    <Module
      label='Critical'
      minValue={constants.CritMinValue}
      headLabels={React.useMemo(
        () => ['Rate', 'Damage Multiplier', 'Expected Damage'],
        []
      )}
      calcData={dataRow}
    />
  )
}
